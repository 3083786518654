import React, {useState} from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import ContactForm from '../components/contact-form'
import ContactFormSubmitted from '../components/contact-form-submitted'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query ContactPageQuery {
      contact: sanityContact(_id: { regex: "/(drafts.|)contact/" }) {
        title
        _rawContactText(resolveReferences: {maxDepth: 5})
        _rawSubmittedText(resolveReferences: {maxDepth: 5})
      }
    }
`

function ContactPage(props) {
  const [submitted, setSubmitted] = useState(false)
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const contact = (data || {}).contact
  if (!contact) {
    throw new Error(
      'Missing "Contact". Open the studio at http://localhost:3333 and add some content to "Contact" and restart the development server.'
    )
  }

  // console.log(props.location.state)

  function submitStatus(status) {
    setSubmitted(status)
  }
  
  return (
    <Layout>
      <SEO title='Contact' />
      <Container>
        {submitted ? <ContactFormSubmitted contactText={contact}/> : <ContactForm contactText={contact} enquiry={(props.location.state && props.location.state.enquiry) ? props.location.state.enquiry : null} submitStatus={submitStatus}/>}
      </Container>
    </Layout>
  )
}

export default ContactPage
