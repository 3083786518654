import React, {useState} from 'react'
import styles from './contact-form.module.css'
import PortableText from './portableText'
import {format} from 'date-fns'


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

function ContactForm(props) {
  
  let {title, year, sold} = props.enquiry ? props.enquiry : ''
  let y = format(new Date(year), 'YYYY')
  let enquiryText = `I would like to enquire about '${title}' ${y}${sold ? ' (Sold)' : ''}.` 
  const [nameInput, setNameInput] = useState('')
  const [emailInput, setEmailInput] = useState('')
  const [messageInput, setMessageInput] = useState(props.enquiry ? enquiryText : '')


  function handleSubmit(event){    
    event.preventDefault()

    let state = {name: nameInput, email: emailInput, message: messageInput}
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...state })
    })
      .then((res) => {
        props.submitStatus(true)
        //console.log(res)
      })
      .catch(error => alert(error));

  }

  function handleChange(event, func){
    func(event.target.value)
  }
  // console.log(props)
  return (
    <div className={styles.formContainer}>
    { props.contactText.title ? <h3>{props.contactText.title}</h3> : <h3>Contact</h3>}
    { props.contactText && (
        <PortableText blocks={props.contactText._rawContactText} />
    )}

    <br></br>  
    <form onSubmit={(event)=>handleSubmit(event)} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <p className="hidden" style={{display: 'none'}}>
          <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
        </p>
        <p>
          <label>Name<span>*</span>: <input type="text" name="name" required onChange={(e)=> handleChange(e, setNameInput)} value={nameInput}/></label>   
        </p>
        <p>
          <label>Email<span>*</span>: <input type="email" name="email" required onChange={(e)=> handleChange(e, setEmailInput)} value={emailInput}/></label>
        </p>
        <p>
          <label>Message<span>*</span>: <textarea name="message" required onChange={(e)=> handleChange(e, setMessageInput)} value={messageInput}></textarea></label>
        </p>
        
          <button type="submit" className={styles.contactFormSubmit}>Submit</button>
        
      </form>
    </div>
  )
}

export default ContactForm
