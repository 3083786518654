import React from 'react'
import styles from './contact-form.module.css'
import {Link} from 'gatsby'
import PortableText from './portableText'


function ContactForm(props) {

  function handleSubmit(event){
    event.preventDefault()
    props.submitStatus(true)
  }

  return (
    <div className={styles.formContainer} style={{textAlign: 'center'}}>
      { props.contactText._rawSubmittedText && (
        <PortableText className={styles.formSubmittedText} blocks={props.contactText._rawSubmittedText} />
      )}
      <Link to='/artwork/' className={styles.contactFormSubmit} style={{marginBottom: '2rem'}}>Artwork</Link>
    </div>

  )
}

export default ContactForm
